@font-face {
  font-family: MuseoSans;
  src: url("./assets/fonts/MuseoSans-500_italic.ttf");
  fontstyle: "italic";
  format: ("ttf");
  font-display: swap;
}

@font-face {
  font-family: MuseoSans;
  src: url("./assets/fonts/MuseoSans-500.ttf");
  fontstyle: "normal";
  format: ("ttf");
  font-display: swap;
}

@font-face {
  font-family: MuseoSansRounded;
  src: url("./assets/fonts/MuseoSansRounded-500.ttf");
  fontstyle: "normal";
  fontweight: 500;
  format: ("ttf");
  font-display: swap;
}

@font-face {
  font-family: MuseoSansRounded;
  src: url("./assets/fonts/MuseoSansRounded-900.ttf");
  fontstyle: "normal";
  fontweight: 900;
  format: ("ttf");
  font-display: swap;
}

#cardNumber,
#expirationDate,
#securityCode,
#form-checkout__securityCode-container {
  font-family: "MuseoSans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-size: 15px;
  padding: 6px 12px;
  border-radius: 2rem;
  width: 100%;
  height: 64px;
  margin-bottom: 10px;
}

.swiper {
  z-index: 0;
}

.ptr,
.ptr__children {
  overflow: hidden auto !important;
}
